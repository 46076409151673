import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { customActionGet } from '@core/utils/utils'
import { stringify } from 'qs'

// const endpoint = () => 'products'
const endpointSalesRentalQuote = () => 'orders/rental-sale-v2'
const endpointCalendarState = () => 'orders/rental/calendar-state'

const Actions = crudActions(endpointSalesRentalQuote)
const rentalSalesList = (_, queryParams) => customActionGet(endpointSalesRentalQuote(), queryParams, _)
const getActiveAttachment = ({ commit }, { id, queryParams }) => customActionGet(`customers/${id}/attachments`, queryParams, commit, 'LIST_ATTACHMENTS')
const createSalesRentalQuote = (ctx, data) => axios.post(endpointSalesRentalQuote(), data)
const getOtherChargesList = () => (ctx, queryParams) => axios.get('/catalog/non-stocks', { params: queryParams })
// eslint-disable-next-line no-empty-pattern
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)

const getRentalSaleOrderItem = (ctx, queryParams) => axios.get('/orders/rental/order-item', { params: queryParams, paramsSerializer: params => stringify(params) })

const rentalOrders = (ctx, queryParams) => axios.get('/orders/rental/order-item', { params: queryParams, paramsSerializer: params => stringify(params) })
const subRentalOrders = (ctx, queryParams) => axios.get('/orders/sub-rental/order-item', { params: queryParams, paramsSerializer: params => stringify(params) })
const saleOrders = (ctx, queryParams) => {
  // eslint-disable-next-line no-prototype-builtins
  if (!queryParams.hasOwnProperty('f')) {
    // eslint-disable-next-line no-param-reassign
    queryParams = { ...queryParams, ...{ f: { is_active: 1 } } }
  }
  return axios.get('/orders/sale/order-item', { params: queryParams, paramsSerializer: params => stringify(params) })
}
const getCalendarState = (ctx, { id }) => axios.get(`${endpointCalendarState()}/?product_id=${id}`)

// eslint-disable-next-line camelcase
const createDraft = (ctx, data) => axios.post(`${endpointSalesRentalQuote()}/draft`, data)
// eslint-disable-next-line camelcase
const updateDraft = (ctx, data) => {
  const id = data.get('id') ? data.get('id') : data.id
  return axios.post(`${endpointSalesRentalQuote()}/${id}/draft`, data)
}

const updateStatusOfItem = (ctx, payload) => {
  const { id, status } = payload
  return axios.get(`${endpointSalesRentalQuote()}/${id}/${status}`)
}

const getDefaultCustomer = ctx => axios.get(`${endpointSalesRentalQuote()}/default-sale-order`)
const getAsset = (ctx, payload) => axios.post('/orders/rental_sale/scan', payload)
const directStore = (ctx, payload) => axios.post(`${endpointSalesRentalQuote()}/direct-store`, payload)

const emailAgain = (ctx, { id }) => axios.get(`${endpointSalesRentalQuote()}/${id}/email-again`)

const hold = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/hold`)
const sendByEmail = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/send-by-email`)
const changeQuote = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/change-quote`)
const placeOrder = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/place-order`)
const releaseAssets = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/release-assets`)
const voidOrder = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/void`)
const approve = (ctx, { id }) => axios.patch(`${endpointSalesRentalQuote()}/${id}/approve`)

export default {
  ...Actions,
  createSalesRentalQuote,
  getCustomer,
  getActiveAttachment,
  endpointSalesRentalQuote,
  rentalSalesList,
  getOtherChargesList,
  subRentalOrders,
  rentalOrders,
  saleOrders,
  createDraft,
  updateDraft,
  updateStatusOfItem,
  getCalendarState,
  getRentalSaleOrderItem,
  hold,
  sendByEmail,
  placeOrder,
  releaseAssets,
  voidOrder,
  approve,
  emailAgain,
  changeQuote,
  getDefaultCustomer,
  getAsset,
  directStore,
}
