<template>
  <div class="l-tableList-collector">
    <b-row>
      <b-col md="6">
        <div class="d-flex align-items-center justify-content-end">
          <button-dashed
            v-if="isDelete"
            ref="deleteBtn"
            :disabled="isBatchDeleteArrNotEmpty"
            :class="isBatchDeleteArrNotEmpty ? 'opacity-50': null"
            @click="deleteButtonHandler"
          >
            <feather-icon
              icon="LTrashIcon"
              size="22"
              class="lightIcon m-0"
            />
          </button-dashed>
          <button-dashed
            v-if="isImport"
            @click="importButtonHandler"
          >
            <feather-icon
              icon="LImportIcon"
              size="16"
              class="lightIcon"
            />
            {{ $t('Import') }}
          </button-dashed>
          <button-dashed v-if="isPrint">
            <feather-icon
              icon="LPrintIcon"
              size="16"
              class="lightIcon"
            />
            {{ $t('Print') }}
          </button-dashed>
          <slot name="filter">
            <feather-icon
              v-if="createPageUrl"
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="34"
            />
          </slot>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="d-flex justify-content-start my-1"
      >
        <button-dashed
          v-if="filterClass"
          @click="filterToggle = !filterToggle"
        >
          <feather-icon
            icon="LFilterIcon"
            size="16"
            class="lightIcon"
          />
          <span class="filterTitle"> {{ $t('Filter By') }}</span>
          <feather-icon
            icon="LArrowDownIcon"
            size="16"
            class="defaultIconColor"
          />
        </button-dashed>
      </b-col>
    </b-row>
    <b-row v-if="filterClass">
      <b-col md="12">
        <component
          :is="`${moduleName}FilterComponent`"
          :filter-toggle="filterToggle"
          :filters="filters"
          :filter-class="filterClass"
          :selected-filters="selectedFilters"
          @onFilter="onFilter"
          @onRemoveSelectedFilter="refetchData"
        />
      </b-col>
    </b-row>
    <!--Table DraganAble == == == == == == == == == == == == -->
    <b-table-simple
      v-if="isDraganAble"
      responsive
      striped
      class="dragg-table mb-0"
    >
      <b-thead>
        <b-tr>
          <b-th style="width: 30px; padding: 0" />
          <b-th
            v-for="col in tableColumns"
            :key="col.key"
            scope="col"
            style="padding: 7px 12px"
            :style="col.thStyle"
          >
            <slot
              :name="headSlotName(col.key)"
              :data="col"
            >
              {{ getHeader(col) }}
            </slot>
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-if="!isBusy"
        id="drag-and-drop-table"
        v-model="collectionOfListArr"
        tag="tbody"
        table-class="table-sm"
        :group="{ put: false }"
        v-bind="defaultDragOptions()"
        @start="onDragStart"
        @end="onDragEnd"
      >
        <b-tr
          v-for="item in collectionOfListArr"
          :id="item.id"
          :key="item.id"
          @mouseenter="onMouseEnterToTableRowAppearDragIcon(item.id, item, $event)"
          @mouseleave="onMouseLeaveToTableRowAppearDragIcon(item.id)"
        >
          <b-td
            :id="`td-${item.id}`"
            :class="`td-${item.id}`"
          >
            <div
              :class="item.id === dragIconVisibleId ? 'line-btn--visible': 'line-btn--unVisible'"
              class="line-btn"
            >
              <div class="line-btn--line" />
              <div class="line-btn--line" />
            </div>
          </b-td>
          <b-td
            v-for="col in tableColumns"
            :id="`td-${item.id}`"
            :key="col.key"
            style="vertical-align: top; position: relative"
          >  <slot
               :name="cellSlotName(col.key)"
               :data="item"
             >
               {{ getValueOfItem(col.key, item) }}
             </slot>
            <div v-if="col.key === 'actions' && hasBelowActions">
              <slot
                :data="item"
                name="below-actions"
              />
            </div>
          </b-td>
        </b-tr>
      </draggable>
      <tbody v-else>
        <tr>
          <td
            :colspan="tableColumns.length + 1"
            class="text-center"
          >
            <div class="text-center text-danger my-2">
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-if="!collectionOfListArr.length && !isBusy">
        <tr>
          <td
            :colspan="tableColumns.length + 1"
            class="text-center bg-white"
          >
            <div class="text-center my-2">
              List Empty
            </div>
          </td>
        </tr>
      </tbody>
      <!--  Table Footer Slots    -->

    </b-table-simple>
    <!--Table DraganAble == == == == == == == == == == == == -->

    <!--Table == == == == == == == == == == == == -->
    <b-table-simple
      v-else
      responsive
      striped
      class="dragg-table mb-0"
    >
      <b-thead>
        <b-tr>
          <b-th style="width: 30px; padding: 0" />
          <b-th
            v-for="col in tableColumns"
            :key="col.key"
            scope="col"
            style="padding: 7px 12px"
            :style="col.thStyle"
          >
            <slot
              :name="headSlotName(col.key)"
              :data="col"
            >
              {{ getHeader(col) }}
            </slot>
          </b-th>
        </b-tr>
      </b-thead>
      <tbody v-if="!isBusy">
        <b-tr
          v-for="item in collectionOfListArr"
          :id="item.id"
          :key="item.id"
          @mouseenter="onMouseEnterToTableRowAppearDragIcon(item.id, item, $event)"
          @mouseleave="onMouseLeaveToTableRowAppearDragIcon(item.id)"
        >
          <b-td />
          <b-td
            v-for="col in tableColumns"
            :id="`td-${item.id}`"
            :key="col.key"
            style="vertical-align: top; position: relative"
          >  <slot
               :name="cellSlotName(col.key)"
               :data="item"
             >
               {{ getValueOfItem(col.key, item) }}
             </slot>
            <div v-if="col.key === 'actions' && hasBelowActions">
              <slot
                :data="item"
                name="below-actions"
              />
            </div>
          </b-td>
        </b-tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td
            :colspan="tableColumns.length + 1"
            class="text-center"
          >
            <div class="text-center text-danger my-2">
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!collectionOfListArr.length && !isBusy">
        <tr>
          <td
            :colspan="tableColumns.length + 1"
            class="text-center bg-white"
          >
            <div class="text-center my-2">
              List Empty
            </div>
          </td>
        </tr>
      </tbody>
      <!--  Table Footer Slots    -->

    </b-table-simple>
    <!--Table == == == == == == == == == == == == -->
    <slot
      v-if="isPagination"
      name="pagination"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('table summary', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </slot>
    <b-tfoot class="d-flex flex-column">
      <div
        v-if="isSearchable"
        class="w-100"
      >
        <autosuggest
          ref="autocomplete"
          v-model="searchQuery"
          class="l-tableList-collector__auto-suggest w-100"
          :suggestions="suggestions"
          :input-props="getSearchInputProps()"
          v-bind="getSuggestProps()"
          :table-suggestion="true"
          :table-suggestion-configs="tableSuggestionConfigs"
          @input="fetchResults"
          @selected="selectHandler"
          @blur="resetValues"
          @item-changed="resetSearchQueryOnClick"
        >
          <template slot="before-input">
            <b-spinner
              v-if="searchLoading"
              class="spinner"
              variant="secondary"
              small
            />
          </template>
          <template slot="after-input">
            <b-badge
              v-if="isAutoSuggestItemExists"
              class="badge"
              variant="warning"
            >
              Exist
            </b-badge>
          </template>
        </autosuggest>
      </div>
    </b-tfoot>
    <tfoot>
      <b-tr>
        <b-th style="width: 30px; padding: 0" />
        <b-th
          v-for="col in tableColumns"
          :key="col.key"
          scope="col"
          style="padding: 7px 12px"
          :style="col.tBodyThStyle"
        >
          <slot
            :name="footSlotName(col.key)"
            :data="col"
          >
            {{ }}
          </slot>
        </b-th>
      </b-tr>
    </tfoot>
  </div>
</template>

<script>

import {
  BTable, BRow, BCol,
  BPagination, BButton, BBadge, BSpinner, BFormCheckbox, BTableSimple, BTbody, BThead, BTd, BTr, BTh, BTfoot,
} from 'bootstrap-vue'
import Vue from 'vue'
import tableConfig from '@/libs/tableConfig'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import draggable from 'vuedraggable'
import { debounce } from '@core/utils/utils'
import Autosuggest from './AutoSuggest/Autosuggest.vue'
// eslint-disable-next-line import/extensions
import AutoSuggestSection from './AutoSuggest/parts/AutoSuggestSection.js'

export default {
  name: 'LDraggableTableListCollector',
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BButton,
    BSpinner,
    ButtonDashed,
    Autosuggest,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BThead,
    BTd,
    BBadge,
    BTr,
    BTfoot,
    BTh,
    draggable,
    AutoSuggestSection,
  },
  props: {
    tableConfigOptions: {
      type: Object,
      default: () => ({}),
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    fetchedData: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      required: true,
    },
    tableProps: {
      type: Object,
      default: () => ({}),
    },
    createPageUrl: {
      type: [Object, String],
      default: () => '',
    },
    searchProps: {
      type: Object,
      default: () => ({}),
    },
    searchInputProps: {
      type: Object,
      default: () => ({}),
    },
    isAutoSuggest: {
      type: Boolean,
      default: true,
    },
    isFooter: {
      type: Boolean,
      default: false,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
    isImport: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isDeleteBtnDisabled: {
      type: Boolean,
      default: false,
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    itemIdForCheckItself: {
      type: [Number, String],
      default: () => null,
    },
    rowClicked: {
      type: [Function, String, null],
      default: null,
    },
    autosuggestListItemName: {
      type: String,
      default: () => 'name',
    },
    sortByTable: {
      type: String,
      default: () => 'id',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    hasBelowActions: {
      type: Boolean,
      default: false,
    },
    tableSuggestionConfigs: {
      type: Array,
      required: false,
      default: () => [],
    },
    additionalObjectForSelectedItem: {
      type: Object,
      default: () => {},
    },
    isExistDisabled: {
      type: Boolean,
      default: false,
    },
    isDraganAble: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      suggestions: [],
      collectionOfListArr: [...this.fetchedData],
      filterToggle: false,
      batchDeleteArr: [],
      autoSuggestLoading: false,
      isAutoSuggestItemExists: false,
      dragIconVisibleId: null,
      items: [],
    }
  },
  computed: {
    searchLoading() {
      return this.autoSuggestLoading && this.searchQuery
    },
    headSlotNames() {
      return this.tableColumns.map(item => `head(${item.key})`)
    },
    footSlotNames() {
      return this.tableColumns.map(item => `foot(${item.key})`)
    },
    cellSlotNames() {
      return this.tableColumns.map(item => `cell(${item.key})`)
    },
    tableList() {
      return this.$store.state[this.moduleName][this.moduleName]
    },
    isAllChecked() {
      if (this.tableList.length) {
        return this.tableList?.length === this.batchDeleteArr.length
      }
      return false
    },
    isBatchDeleteArrNotEmpty() {
      return this.batchDeleteArr.length === 0
    },
  },
  updated() {
    this.$emit('updateHandler')
  },
  methods: {
    rowHoveredHandler(data) {
      this.$emit('rowHoveredHandler', { data })
    },
    rowUnHoveredHandler() {
      this.$emit('rowUnHoveredHandler')
    },
    headSlotName(key) {
      return `head(${key})`
    },
    cellSlotName(key) {
      return `cell(${key})`
    },
    footSlotName(key) {
      return `foot(${key})`
    },
    fetchTableItemsList() {
      return this.list(this, data => {
        this.items = data
      })
    },
    getHeader: col => col.label ?? col.key,
    getValueOfItem: (key, item) => item[key],
    onDragStart() {
      this.$emit('onDragStart')
    },
    onDragEnd() {
      this.$emit('onDragEnd', this.collectionOfListArr)
    },
    onMouseEnterToTableRowAppearDragIcon(id, item, e) {
      this.$emit('rowHoveredHandler', item)
      this.dragIconVisibleId = +e.target.id
    },
    onMouseLeaveToTableRowAppearDragIcon() {
      this.rowUnHoveredHandler()
      this.dragIconVisibleId = null
    },
    defaultDragOptions() {
      const defaultDragOptions = {
        animation: 200,
        group: 'description',
        disabled: this.isEditable,
        ghostClass: 'ghost',
      }
      return {
        ...defaultDragOptions,
        ...this.dragOptions,
      }
    },
    resetValues() {
      this.isAutoSuggestItemExists = null
    },
    getTableProps() {
      const defaultTableProps = {
        ref: `${this.moduleName}Table`,
        items: Array.from(this.collectionOfListArr),
        responsive: true,
        fields: this.tableColumns,
        'primary-key': 'id',
        'show-empty': true,
        'empty-text': this.$t('List Empty'),
        busy: this.isBusy,
        class: 'position-relative',
        striped: true,
        'foot-clone': this.isFooter,
      }
      if (this.rowClicked) {
        defaultTableProps.hover = true
      }
      return {
        ...defaultTableProps,
        ...this.tableProps,
      }
    },
    myRowClickHandler(data, row, el) {
      if (this.rowClicked) {
        if (typeof this.rowClicked === 'function') {
          this.rowClicked(data)
        } else {
          this.gotoNewPage({ name: this.rowClicked, path: `${data.id}`, params: { id: data.id } }, el)
        }
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
        ...this.searchProps,
      }
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'table-search',
        // style: 'background: #FBFBFB',
        disabled: this.isEditable,
      }
      return {
        ...defaultInputProps,
        ...this.searchInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },

    getSelectOptions(params) {
      this.suggestions = []
      this.autoSuggestLoading = true
      if (params && params.length > 2) {
        this.$store.dispatch(this.endpoint, {
          ...this.extraParams,
          search: params,
          per_page: 50,
        }).then(res => {
          const { data } = this.getBody(res)
          this.suggestions = [{ data }]
        }).finally(() => {
          this.autoSuggestLoading = false
        })
      }
    },

    resetSearchQueryOnClick() {
      this.$refs.autocomplete.internalValue = null
    },
    fetchResults(searchQuery) {
      if (this.collectionOfListArr.length > 0 && searchQuery) {
        const existItem = this.collectionOfListArr?.find(collectionOfListItem => collectionOfListItem[this.autosuggestListItemName].toLowerCase().split(' ').join('') === searchQuery.toLowerCase().split(' ').join(''))

        this.isAutoSuggestItemExists = !!existItem
      }

      this.search(searchQuery, this)
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 500),

    selectHandler(suggest) {
      if (!suggest) return

      const { item } = suggest

      if (this.additionalObjectForSelectedItem) {
        // eslint-disable-next-line no-restricted-syntax
        for (const additionalItem in this.additionalObjectForSelectedItem) {
          item[additionalItem] = this.additionalObjectForSelectedItem[additionalItem]
        }
      }

      if (this.itemIdForCheckItself && !this.checkForAddingItself(item)) return

      const isExist = this.collectionOfListArr?.find(collectionOfListItem => collectionOfListItem.id === item.id)

      if (isExist && !this.isExistDisabled) {
        this.isAutoSuggestItemExists = true
      } else {
        this.collectionOfListArr.push(item)
        this.$emit('getCollectedList', this.collectionOfListArr)
        this.suggestions = []
      }

      this.$emit('onSelectHandler')
    },
    checkForAddingItself(itemForCheck) {
      if (itemForCheck.id === this.itemIdForCheckItself) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('You can\'t add this product to itself'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    importButtonHandler() {
      this.$emit('importButtonHandler')
    },
    deleteButtonHandler() {
      this.confirmNotification(this, this.batchDeleteArr, `${this.moduleName}/batchDelete`)
        .then(() => {
          this.refetchData()
          this.$nextTick(() => {
              // eslint-disable-next-line no-unused-expressions
              this.$refs?.deleteBtn.$el.setAttribute('disabled', true)
          })
        })
    },
    getAllIds() {
      if (this.tableList.length === this.batchDeleteArr.length) {
        this.batchDeleteArr = []
      } else if (this.batchDeleteArr.length === 0) {
        this.batchDeleteArr.push(...this.tableList.map(customer => customer.id))
      } else {
        // eslint-disable-next-line array-callback-return,consistent-return
        const filteredArr = this.tableList.map(customer => customer.id).filter(id => {
          if (!this.batchDeleteArr.includes(id)) {
            return id
          }
        })
        this.batchDeleteArr.push(...filteredArr)
      }
    },
    addToBatchArr(id) {
      if (this.batchDeleteArr.includes(id)) {
        this.batchDeleteArr = this.batchDeleteArr.filter(batchId => batchId !== id)
      } else {
        this.batchDeleteArr.push(id)
      }
    },
    isChecked(id) {
      return !!this.batchDeleteArr.find(batchId => batchId === id)
    },
    onFilter(filter) {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const field in filter) {
        this.extraParams[field] = filter[field]
      }
      this.filterToggle = false
      this.refetchData()
    },
  },
  watch: {
    fetchedData: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, _) {
        if (val) {
          this.collectionOfListArr = val
        }
      },
    },
  },
  setup(props) {
    const { filterClass } = props
    if (filterClass && filterClass.filterComponent) {
      Vue.component(`${props.moduleName}FilterComponent`, () => filterClass.filterComponent)
    }
    return {
      ...tableConfig(props.moduleName, props.tableConfigOptions, props.filterClass),
    }
  },
}
</script>
<style scoped>
.filterTitle {
  margin-right: 8px;
  font-size: 16px;
}

.searchButton {
  margin: 0 0 0 16px !important;
  padding: 9px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.opacity-50 {
  opacity: 0.50;
}

.l-tableList-collector {

  .table-responsive{
    overflow-x: initial !important;
  }
  .table-responsive.position-relative {
    margin-bottom: 0 !important;
  }

  .b-table-empty-row td {
    border-radius: 5px 5px 0 0 !important;
    border-bottom: none !important;
  }

  td {
    border-radius: 0 !important;
    border-bottom: none !important;
  }

  .table-striped tbody tr:first-child:not(.b-table-empty-row) td:first-child {
    border-radius: 5px 5px 0 0 !important;
  }

  .autosuggest__results {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #646464;
      border-radius: 5px;
    }
  }

  table.table tfoot tr th:last-child {
    border-right: solid 1px #dde3e7;
  }

  table.table tfoot tr th:first-child {
    border-left: solid 1px #dde3e7;
  }

  tfoot {
    display: revert;
  }

  &__auto-suggest {
    height: 40px;
    position: relative;

    & .spinner,
    & .badge {
      position: absolute !important;
    }

    & .spinner {
      right: 8px !important;
      top: 14px !important;
    }

    & .badge {
      right: 8px !important;
      top: 8px !important;
    }

    .form-control {
      border-radius: 0 0 5px 5px !important;
      height: 40px;

      &:focus {
        border: none;
      }
    }
  }

  .line-btn {
    height: 12px;
    padding: 2px 2px;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.400ms ease;

    &--unVisible {
      opacity: 0;
      visibility: hidden;
    }

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    &--line {
      background: #C9C9C9;
      height: 1px;
      width: 100%;
      position: relative;

      &::before,
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        max-height: 1px;
        position: absolute;
      }

      &::before {
        background-color: #ADADAD;
        transform: translateY(-0.8px);
        left: 0;
      }
      &::after {
        background-color: #E2E2E2;
        transform: translateY(0.8px);
        left: 0;
      }
    }
  }

  //.sortable-chosen {
  //  background-color: rgba(151, 149, 149, 0.38) !important;
  //}

  .dragg-table {
    & td {
      padding: 5px 5px !important;
    }
  }

  .autosuggest__results {
    top: 3px;
    border-radius: 0.5rem;

    & tbody tr td {
      border-radius: 0 !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: none !important;
    }
    .table {
      margin-bottom: 0;
    }

    .table-striped tbody tr:first-child:not(.b-table-empty-row) td:first-child {
      border-radius: 0 !important;
    }
    .table-striped tbody tr:first-child:not(.b-table-empty-row) td:last-child {
      border-radius: 0 !important;
    }

    .autosuggest__results-item {
      padding: 7px 10px;
      cursor: pointer !important;
    }

    & thead tr th {
      padding: 7px 10px;
    }
  }
}
</style>
