export default {
  order: {},
  orders: [],
  orderForm: {},
  orderInformationForm: {},
  customerInformationForm: {
    attachments: [],
  },
  insuranceInformationForm: {},
  dispatchAndReturnForm: {},
  eventInformationForm: {},
  orderItemsRentalTable: [],
  orderItemsSalesTable: [],
  orderItemsOtherChargesTable: [],
  totalsForm: {},
  onError: {},
  requiresPurchaseOrder: false,
  isSettledCustomer: false,
  isDispatchAndReturnAllRequiredFieldsFilled: false,
  isEventInformationRequiredFieldsFilled: false,
  isOrderInformationFormRequiredFieldsFilled: false,
  payerAccount: { value: 'renter' },
  allowToPickRentalTimesByLineItem: false,
  canBeSubRented: false,
}
